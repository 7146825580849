.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.paper{
    max-width:350px;
    margin: auto;
    background-color: #FFFFFF;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}